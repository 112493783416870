import React, { FC, ReactElement, ReactNode } from "react"
import { TableColumnsInterface } from "../../../constants/interfaces"
import { get } from "lodash"
import ArrowIcon from "../../icons/ArrowIcon"
import { GameSessions } from "services/user/type"
import TableAccordionColumn from "./TableAccordionColumn"

type TableAccordionRowProps = {
  toggleContent?: () => void
  selectedColumns: TableColumnsInterface[]
  row: any
  onDatePickerClick: (val: any) => void
  setActiveDatePicker: React.Dispatch<React.SetStateAction<string | null>>
  activeDatePicker: string | null
  handleDateChange?: (rowId: string, dates: string[]) => void
  dateData: any
  gameSessions?: GameSessions[]
  isContentOpen: boolean
  content: ReactNode | any
  hasContent: boolean
}
const TableAccordionRow: FC<TableAccordionRowProps> = ({
  toggleContent,
  selectedColumns,
  row,
  onDatePickerClick,
  setActiveDatePicker,
  activeDatePicker,
  handleDateChange,
  dateData,
  gameSessions,
  isContentOpen,
  content,
  hasContent
}): ReactElement => {
  const currency = row?.coin
  return (
    <div
      className="
          border-b border-t-0 border-b-1 last:border-b-0 focus:ring-4
          dark:border-gray-700 dark:text-gray-400
          "
    >
      <button
        type="button"
        className={`flex items-center justify-between w-full font-medium rtl:text-right text-gray-400 relative ${
          hasContent ? "cursor-pointer" : "cursor-auto"
        } ${hasContent && "dark:hover:bg-gray-800"} ${isContentOpen && "bg-gray-800"}`}
        onClick={toggleContent}
      >
        {selectedColumns
          .filter((column) => column.selected === true)
          .map((column: TableColumnsInterface, colIndex: number) => {
            let rawValue = get(row, column.value)
            if (column.value === "other.paymentMethod") {
              const method = get(row, column.value)
              const coin = get(row, "coin")
              rawValue = method || coin
            }

            return (
              <TableAccordionColumn
                key={colIndex}
                column={column}
                rawValue={rawValue}
                onDatePickerClick={() => onDatePickerClick(row)}
                setActiveDatePicker={() => setActiveDatePicker((prev) => (prev === row.id ? null : row.id))}
                activeDatePicker={activeDatePicker === row.id}
                dateData={dateData}
                handleDateChange={(dates: string[]) => handleDateChange?.(row.id, dates)}
                gameSessions={gameSessions}
                currency={currency}
                style={{ width: `${100 / selectedColumns.length}%` }}
              />
            )
          })}
        {isContentOpen && <ArrowIcon rotate={isContentOpen} className="absolute right-2" />}
      </button>
      {isContentOpen && <div className="border dark:border-gray-700 border-t-0 px-2 pb-2">{content}</div>}
    </div>
  )
}

export default TableAccordionRow
