export const URL = {
  HOME: "/",
  USERS: "/users",
  CMS: "/cms",
  DASHBOARD: "/dashboard",
  TRANSACTIONS: "/transactions",
  BANK_TRANSFER: "/bank-tansfer",
  USER: "/user/:id",
  PROMO_CODES: "/promo-codes",
  ACTIVITY: "/activity",
  BONUSES: "/bonuses",
  KYC_FILES: "/kyc-files",
  REFERRALS: "/referrals",
  NOT_FOUND: "*"
}

export const REQ = {
  AUTH: {
    LOGIN: "v1/auth/login",
    LOGOUT: "v1/auth/logout",
    REFRESH: "v1/auth/refresh_token"
  },
  USER: {
    GET: "v1/user"
  },
  CMS: {
    GET_ACTIVE: "/v1/cms/list"
  },
  ADMIN_DASHBOARD: {
    USERS: "/admin/dashboard/users",
    USERS_COUNT: "/admin/dashboard/user-count",
    STATS: "/admin/dashboard/stats",
    STATS_BY_YEAR: "/admin/dashboard/stats/yearly",
    STATS_BY_MONTH: "/admin/dashboard/stats/monthly",
    STATS_BY_DAY: "/admin/dashboard/stats/daily",
    STATS_BY_HOUR: "/admin/dashboard/stats/hourly",
    ACTIVITY: "/admin/activity",
    AFFILIATED_USERS: "/admin/dashboard/affiliated-users/:userId",
    USER: {
      USER: "/admin/user/user/:id",
      USER_BETS: "/admin/user/:id/bets",
      USER_TRANSACTIONS: "/admin/user/:id/transactions",
      USER_REFERRALS: "/admin/user/:id/referrals",
      USER_BALANCE: "/admin/user/:id/balance",
      SWAP_USER_BALANCE: "/admin/user/:id/swap-balance",
      USER_REWARD_GAMES: "/admin/user/:id/reward-games",
      USER_ADD_REWARD_GAME: "/admin/user/user-add-reward-game",
      USER_DELETE_REWARD_GAME: "/admin/user/user-delete-reward-game",
      ACCOUNT_ACTIVITIES: "/admin/user/:id/account-activities",

      USER_COMMENTS: "/admin/user/:id/comments",
      ADD_COMMENTS: "/admin/user/comments",

      GET_BONUS: "/admin/user/:id/bonus",
      GET_TOTAL_BONUS: "/admin/user/:id/total-bonus",
      CANCEL_BONUS: "/admin/user/bonus/cancel",
      CLAIM_CUSTOM_BONUS: "/admin/user/bonus"
    },
    BONUSE: {
      LIST: "/admin/bonus/list",
      CREATE: "/admin/bonus/",
      UPDATE: "/admin/bonus/:id"
    },
    PROMO_CODES: {
      GET: "/admin/promo-codes",
      UPDATE: "/admin/promo-codes/:id",
      CREATE: "/admin/promo-codes"
    },
    CMS: {
      GET: "/admin/cms/list",
      UPDATE: "/admin/cms/:id",
      CREATE: "/admin/cms",
      DELETE: "/admin/cms/:id"
    },
    CMSImage: {
      UPLOAD_IMAGE: "/admin/cms-image/upload-image"
    },
    CMSText: {
      GET: "/admin/cms/text/list",
      UPDATE: "/admin/cms/text/:id",
      CREATE: "/admin/cms/text"
    },
    TRANSACTIONS: {
      GET: "/admin/transactions",
      DEPOSIT_TOTAL: "/admin/transactions/deposit-total",
      WITHDRAWAL_TOTAL: "/admin/transactions/withdrawal-total",
      WITHDRAWAL_PENDING: "/admin/transactions/withdrawal-pending",
      BETS_TOTAL: "/admin/transactions/bet-total",
      WINS_TOTAL: "/admin/transactions/win-total",
      AFFILIATE_TOTAL: "/admin/transactions/affiliate-total",
      RAKEBACK_TOTAL: "/admin/transactions/rakeback-total",
      PROMOCODE_TOTAL: "/admin/transactions/promo-total",
      CONFIRM: "/admin/transactions/confirm-payout",
      DECLINE: "/admin/transactions/decline-payout",
      PENDING_WITHDRAWALS_COUNT: "/admin/transactions/submitted-withdrawals-count"
    },
    BANK_TRANSFER: {
      APPROVE: "/admin/transactions/approve-bank-transfer"
    },
    PAYADMIT: {
      APPROVE: "/admin/transactions/payadmit-approve-payout",
      DECLINE: "/admin/transactions/payadmit-decline-payout",
      SUBMITTED: {
        APPROVE: "/admin/transactions/payadmit-submitted-approve-payout",
        DECLINE: "/admin/transactions/payadmit-submitted-decline-payout"
      }
    },
    CRYPTO: {
      APPROVE: "/admin/transactions/crypto-approve-payout",
      DECLINE: "/admin/transactions/crypto-decline-payout",
      SUBMITTED: {
        APPROVE: "/admin/transactions/crypto-submitted-approve-payout",
        DECLINE: "/admin/transactions/crypto-submitted-decline-payout"
      }
    },
    GAMES: {
      FREE_SPIN_GAMES: "/admin/game/free-spin-games",
      FREE_SPIN_GAME_PROVIDERS: "/admin/game/free-spin-game-providers"
    },
    KYC_FILES: {
      GET: "/admin/kyc-files",
      GET_ALL: "/admin/kyc-files/all",
      UPDATE: "/admin/kyc-files",
      PENDING_COUNT: "/admin/kyc-files/pending-count",
      CHECK_DUPLICATE: "/admin/kyc-files/check-duplicate",
      KYC_READ: "v1/upload/generate-read-presigned-url"
    },
    PAYMENTS: {
      CUR_EXCHANGE_RATES: "v1/payments/cur-exchange-rates"
    },
    TENANTS: {
      LIST: "/admin/tenants",
      UPDATE: "/admin/tenants/:id/update"
    },
    REFERRALS: {
      LIST: "/admin/referrals/referrers",
      REFERRALS_CODES_LIST: "/admin/referrals/referrals-codes/:referrerId",
      REFERRALS_LIST: "/admin/referrals/referrals/:code"
    }
  }
}
