import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts"
import { useState, FC, memo, useMemo } from "react"
import { PeriodStats } from "services/total/types"
import Chevron from "../../assets/icons/chevron-icon.svg"
import { formatFiatCurrency } from "util/formatter"

const statsOptions = [
  { label: "Active Users", value: "activeUsers", color: "#16a34a" },
  { label: "Withdrawal Count", value: "withdrawalCount", color: "#f87171" },
  { label: "FTD Count", value: "depositers", color: "#6b7280" },
  { label: "Deposit Count", value: "depositCount", color: "#facc15" },
  { label: "Registrations", value: "userCount", color: "#4ade80" },
  { label: "Total Wins", value: "win", color: "#3b82f6", type: "currency" },
  { label: "Total Wager", value: "wager", color: "#eab308", type: "currency" },
  { label: "GGR", value: "ggr", color: "#f97316", type: "currency" },
  { label: "FTD Sum", value: "ftdSum", color: "#8b5cf6", type: "currency" },
  { label: "Average Bet", value: "averageBet", color: "#ec4899", type: "currency" },
  { label: "Deposit Total", value: "deposit", color: "#14b8a6", type: "currency" },
  { label: "Withdrawal Total", value: "withdrawal", color: "#f43f5e", type: "currency" },
  { label: "Net Deposits", value: "netDeposits", color: "#0ea5e9", type: "currency" }
]

const currencyStats = new Set(["ggr", "deposit", "withdrawal", "netDeposits", "ftdSum", "averageBet", "win", "wager"])

interface StatisticChartProps {
  data: { grouped: PeriodStats[]; pp?: PeriodStats[] }
  loading: boolean
  granularity: "year" | "month" | "day" | "hour"
}

const StatisticChart: FC<StatisticChartProps> = ({ data, loading, granularity }) => {
  const [selectedStats, setSelectedStats] = useState<string[]>(["activeUsers"])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [warning, setWarning] = useState("")
  const [showPP, setShowPP] = useState(false)

  const formattedData = useMemo(() => {
    return data.grouped.map((item) => ({
      ...item,
      ggr: item.wager - item.win
    }))
  }, [data.grouped])

  const formattedPP = useMemo(() => {
    return (data.pp || []).map((item) => ({
      ...item,
      ggr: item.wager - item.win
    }))
  }, [data.pp])

  const mergedData = useMemo(() => {
    return formattedData.map((item, index) => {
      const ppItem = (formattedPP[index] || {}) as Record<string, any>
      const merged: Record<string, any> = { ...item }
      selectedStats.forEach((stat) => {
        merged[`pp_${stat}`] = ppItem[stat] ?? null
      })

      merged._ppPeriod = ppItem.period || null

      return merged
    })
  }, [formattedData, formattedPP, selectedStats])

  const handleStatToggle = (value: string, checked: boolean) => {
    const clickedType = statsOptions.find((opt) => opt.value === value)?.type || "count"
    const selectedTypes = selectedStats.map((val) => statsOptions.find((opt) => opt.value === val)?.type || "count")

    const isMixingTypes = selectedTypes.length > 0 && selectedTypes.some((t) => t !== clickedType)

    if (isMixingTypes && checked) {
      setWarning("Cannot mix currency and non-currency stats.")
      setTimeout(() => setWarning(""), 10000)
      return
    }

    setSelectedStats((prev) => (checked ? [...prev, value] : prev.filter((v) => v !== value)))
    setWarning("")
  }

  const dynamicLeftMargin = useMemo(() => {
    if (!formattedData.length || selectedStats.length === 0) return 30

    const statKey = selectedStats[0]
    const values = formattedData.map((item) => item[statKey as keyof typeof item])
    const max = Math.max(...(values as number[]))

    const isCurrency = statsOptions.find((s) => s.value === statKey)?.type === "currency"
    const formatted = isCurrency ? formatFiatCurrency(max) : max.toString()

    console.log("formatted.length: ", formatted.length)
    return Math.min(Math.max(formatted.length * 5, 30), 100)
  }, [formattedData, selectedStats, selectedStats.length])

  return (
    <div className="flex flex-col gap-8 p-4 bg-zinc-900 rounded">
      <div className="flex gap-4 items-center">
        <div className="text-2xl shrink-0">Statistic Chart</div>

        <div className="relative w-full max-w-lg">
          <div
            className="flex justify-between items-center border border-gray-400 rounded px-3 py-2 bg-zinc-800 cursor-pointer"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <div className="flex flex-wrap gap-2">
              {selectedStats.length === 0 && <span className="text-gray-500">Select stats...</span>}
              {selectedStats.map((value) => {
                const stat = statsOptions.find((opt) => opt.value === value)
                const label = stat?.label || value
                const color = stat?.color || "#ccc"

                return (
                  <div
                    key={value}
                    className="border border-gray-400 text-white text-sm px-2 py-1 rounded flex items-center gap-2 transition-all hover:bg-gray-500"
                  >
                    <div className="w-2 h-2 rounded-full" style={{ backgroundColor: color }} />
                    {label}
                    <button
                      className="ml-1 text-xs hover:text-red-300"
                      onClick={(e) => {
                        e.stopPropagation()
                        setSelectedStats((prev) => prev.filter((v) => v !== value))
                      }}
                    >
                      ×
                    </button>
                  </div>
                )
              })}
            </div>
            <div className="ml-2 shrink-0">
              <img src={Chevron} alt="Chevron Icon" className={`transition-transform duration-200 ${dropdownOpen ? "rotate-180" : ""}`} />
            </div>
          </div>

          {dropdownOpen && (
            <div className="absolute z-10 mt-1 w-full bg-zinc-800 border border-gray-500 rounded shadow max-h-60 overflow-y-auto">
              <>
                <div className="px-4 py-2 text-md text-gray-200 ">Non-Currency Stats</div>
                {statsOptions
                  .filter((opt) => !opt.type)
                  .map(({ label, value }) => (
                    <label
                      key={value}
                      className="flex items-center justify-between px-4 py-2 pl-8 hover:bg-gray-600 cursor-pointer text-sm"
                    >
                      <span>{label}</span>
                      <input
                        type="checkbox"
                        checked={selectedStats.includes(value)}
                        onChange={(e) => handleStatToggle(value, e.target.checked)}
                      />
                    </label>
                  ))}

                <div className="px-4 py-2 pt-4 text-md border-t border-gray-700 text-gray-200">Currency Stats</div>
                {statsOptions
                  .filter((opt) => opt.type === "currency")
                  .map(({ label, value }) => (
                    <label
                      key={value}
                      className="flex items-center justify-between px-4 py-2 pl-8 hover:bg-gray-600 cursor-pointer text-sm"
                    >
                      <span>{label}</span>
                      <input
                        type="checkbox"
                        checked={selectedStats.includes(value)}
                        onChange={(e) => handleStatToggle(value, e.target.checked)}
                      />
                    </label>
                  ))}
              </>
            </div>
          )}
        </div>
        <label className="flex items-center gap-2 text-sm text-gray-300">
          <input type="checkbox" checked={showPP} onChange={() => setShowPP((prev) => !prev)} className="accent-pink-500" />
          Show Previous Period (PP)
        </label>
        {warning && <p className="mt-2 text-sm text-red">{warning}</p>}
      </div>
      {!loading && (
        <ResponsiveContainer width="100%" height={500}>
          <LineChart data={mergedData} margin={{ top: 10, right: 30, left: dynamicLeftMargin, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 10" vertical={false} />

            <XAxis
              dataKey={granularity === "year" ? "year" : "period"}
              tickFormatter={(tick) => {
                const date = new Date(tick)

                if (granularity === "day") {
                  return date.toLocaleDateString(undefined, { day: "2-digit", month: "short" })
                }

                if (granularity === "hour") {
                  return date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })
                }

                if (granularity === "month") {
                  return date.toLocaleDateString(undefined, { month: "short", year: "numeric" })
                }

                return tick
              }}
            />

            <YAxis
              tickFormatter={(value) => {
                const selectedTypes = selectedStats.map((val) => statsOptions.find((opt) => opt.value === val)?.type || "count")
                const allAreCurrency = selectedTypes.every((type) => type === "currency")
                return allAreCurrency ? formatFiatCurrency(value) : value
              }}
            />

            <Tooltip
              content={({ active, payload, label }) => {
                if (!active || !payload || payload.length === 0) return null

                const ppPeriod = payload[0]?.payload?._ppPeriod // safely extract pp period

                return (
                  <div className="p-3 bg-zinc-800 border border-gray-600 rounded text-sm text-white">
                    <div className="font-bold mb-2">{granularity === "year" ? label : new Date(label).toLocaleString()}</div>
                    <ul className="space-y-1">
                      {payload.map((entry: any) => {
                        const statKey = entry.dataKey as string
                        const isPP = statKey.startsWith("pp_")
                        const originalKey = isPP ? statKey.replace("pp_", "") : statKey
                        const statMeta = statsOptions.find((opt) => opt.value === originalKey)
                        const formatted = currencyStats.has(originalKey) ? formatFiatCurrency(entry.value) : entry.value

                        return (
                          <li key={statKey} className="flex justify-between gap-4">
                            <span style={{ color: entry.color }}>
                              {isPP
                                ? `PP ${statMeta?.label || originalKey} (${new Date(ppPeriod).toLocaleString()})`
                                : statMeta?.label || statKey}
                            </span>
                            <span>{formatted}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              }}
            />

            <Legend wrapperStyle={{ paddingTop: 30 }} />
            {selectedStats.map((statKey) => {
              const stat = statsOptions.find((s) => s.value === statKey)
              return (
                <Line
                  key={statKey}
                  type="monotone"
                  dataKey={statKey}
                  name={stat?.label || statKey}
                  stroke={stat?.color || "#8884d8"}
                  strokeWidth={2}
                  dot={{ r: 3 }}
                  activeDot={{ r: 5 }}
                />
              )
            })}

            {showPP &&
              selectedStats.map((statKey) => {
                const stat = statsOptions.find((s) => s.value === statKey)
                return (
                  <Line
                    key={`pp-${statKey}`}
                    type="monotone"
                    dataKey={`pp_${statKey}`}
                    name={`PP ${stat?.label || statKey}`}
                    stroke={stat?.color || "#8884d8"}
                    strokeDasharray="3 15"
                    strokeWidth={2}
                    dot={false}
                  />
                )
              })}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default memo(StatisticChart)
