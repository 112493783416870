import { FC } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { formatFiatCurrency } from "util/formatter"

type StatCardProps = {
  title: string
  bgColor?: string
  value: number
  previousValue?: number
  loading?: boolean
  type?: string
}

export const StatCard: FC<StatCardProps> = ({ title, value, loading, previousValue, bgColor, type }) => {
  const { t } = useTranslation("landing")

  const rawChange =
    previousValue !== undefined && previousValue !== 0
      ? ((value - previousValue) / previousValue) * 100
      : previousValue === value
      ? 0
      : null

  const percentChange = rawChange !== null ? rawChange.toFixed(1) : null
  const numericChange = Number(percentChange)

  const isUp = numericChange > 0
  const isDown = numericChange < 0

  const colorHex = isUp ? "#4ade80" : isDown ? "#f87171" : "#9ca3af"

  return (
    <div className={`card ${bgColor || "bg-accent"} text-primary-content`}>
      <div className="card-body p-4">
        <h3 className="text-sm">{t(title)}</h3>

        <div
          className={clsx("flex justify-between items-center font-bold text-lg", {
            "loading loading-spinner loading-xs inline-flex": loading
          })}
        >
          {!loading ? (type === "currency" ? formatFiatCurrency(value, "$0") : value) : ""}

          {!loading && percentChange !== null && (
            <div className="flex items-end text-sm leading-none" style={{ color: colorHex }}>
              {isUp && <span>▲</span>}
              {isDown && <span>▼</span>}
              <span>
                {numericChange > 0 ? "+" : ""}
                {percentChange}%
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
