import StatisticChart from "components/charts/StatisticChart"
import MultiOptTimeRangePicker from "components/date/MultiOptTimePeriodPicker"
import DropdownMenu from "components/inputs/DropdownMenu"
import { StatCard } from "components/stats/Card"
import { tenantOptions } from "constants/bonus"
import { useProps } from "contexts/PropsContext"
import { useStats } from "hooks/useStats"
import { useStatsOverTime } from "hooks/useStatsOverTime"
import { useUserCount } from "hooks/useUserCount"
import { ChangeEvent, FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EnumTimePeriod } from "util/dashboard"
import { getPreviousTimeframe } from "util/getPreviousTimeframe"

const Dashboard: FC = () => {
  const { user } = useProps()
  const { t } = useTranslation(["landing"])

  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])
  const [tenant, setTenant] = useState<string>("")

  const previousTimeframe = useMemo(() => getPreviousTimeframe(timeframe), [timeframe])

  const handleChangeTimeFrame = useCallback((dates: (Date | null)[]) => setTimeframe(dates), [])
  const { loading: loadingUserCount, data: userCount } = useUserCount(timeframe, tenant)
  const { loading: loadingPPUserCount, data: userCountPP } = useUserCount(previousTimeframe, tenant)
  const { loading: loadingStats, data: stats } = useStats(timeframe, tenant)
  console.log("stats: ", stats)
  const { loading: loadingStatsOverTime, data: statsOverTime, granularity } = useStatsOverTime(timeframe, tenant)
  console.log("statsOverTime: ", statsOverTime)

  const ggr = useMemo(() => (stats?.wager ?? 0) - (stats?.win ?? 0), [stats])
  const ppGgr = useMemo(() => (stats?.pp?.wager ?? 0) - (stats?.pp?.win ?? 0), [stats])

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      {/* <div className="flex items-center justify-center text-xl text-black dark:text-white">
        {t("welcome", { value: user?.display_name })}
      </div> */}

      <div className="flex max-w-2xl gap-4 flex-col md:flex-row md:gap-0 md:items-end md:mb-3">
        <MultiOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} defaultPeriod={EnumTimePeriod.today} />
        <DropdownMenu
          title="Tenant"
          options={tenantOptions}
          value={tenant}
          onChange={(ev: ChangeEvent<HTMLSelectElement>) => setTenant(ev.target.value)}
        />
      </div>

      <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-5">
        <StatCard title="ggr" bgColor="bg-yellow-500" value={ggr} previousValue={ppGgr} loading={loadingStats} type="currency" />
        <StatCard title="registrations" value={userCount} previousValue={userCountPP} loading={loadingUserCount || loadingPPUserCount} />
        <StatCard
          title="deposit_total"
          bgColor="bg-primary"
          value={stats?.deposit ?? 0}
          previousValue={stats?.pp?.deposit}
          loading={loadingStats}
          type="currency"
        />
        <StatCard
          title="withdrawal_completed"
          bgColor="bg-secondary"
          value={stats?.withdrawal ?? 0}
          previousValue={stats?.pp?.withdrawal}
          loading={loadingStats}
          type="currency"
        />
        <StatCard
          title="bets_total"
          bgColor="bg-orange-600"
          value={stats?.wager ?? 0}
          previousValue={stats?.pp?.wager}
          loading={loadingStats}
          type="currency"
        />
        <StatCard
          title="wins_total"
          bgColor="bg-blue-600"
          value={stats?.win ?? 0}
          previousValue={stats?.pp?.win}
          loading={loadingStats}
          type="currency"
        />
        <StatCard
          title="Claimed Bonus"
          bgColor="bg-lime-600"
          value={stats?.bonus ?? 0}
          previousValue={stats?.pp?.bonus}
          loading={loadingStats}
          type="currency"
        />
        <StatCard
          title="average_bet"
          bgColor="bg-primary"
          value={stats?.averageBet ?? 0}
          previousValue={stats?.pp?.averageBet}
          loading={loadingStats}
          type="currency"
        />

        <StatCard
          title="active_users"
          bgColor="bg-orange-300"
          value={stats?.activeUsers ?? 0}
          previousValue={stats?.pp?.activeUsers}
          loading={loadingStats}
        />
        <StatCard
          title="ftd"
          bgColor="bg-gray-700"
          value={stats?.depositers ?? 0}
          previousValue={stats?.pp?.depositers}
          loading={loadingStats}
        />

        <StatCard
          title="deposit_count"
          bgColor="bg-emerald-500"
          value={stats?.depositCount ?? 0}
          previousValue={stats?.pp?.depositCount}
          loading={loadingStats}
        />
        <StatCard
          title="ftd_sum"
          bgColor="bg-indigo-600"
          value={stats?.ftdSum ?? 0}
          previousValue={stats?.pp?.ftdSum}
          loading={loadingStats}
          type="currency"
        />
        <StatCard
          title="net_deposits"
          bgColor="bg-cyan-700"
          value={stats?.netDeposits ?? 0}
          previousValue={stats?.pp?.netDeposits}
          loading={loadingStats}
          type="currency"
        />
        <StatCard
          title="withdrawal_count"
          bgColor="bg-rose-600"
          value={stats?.withdrawalCount ?? 0}
          previousValue={stats?.pp?.withdrawalCount}
          loading={loadingStats}
        />
      </div>
      {statsOverTime && statsOverTime?.grouped.length > 0 && (
        <StatisticChart data={statsOverTime} loading={loadingStatsOverTime} granularity={granularity} />
      )}
    </div>
  )
}

export default Dashboard
