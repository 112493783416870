import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import totalDataService from "services/total/total.service"
import { PeriodStats, TimeSeriesStatsResponse } from "services/total/types"

type Granularity = "year" | "month" | "day" | "hour"

export const useStatsOverTime = (timeframe: (Date | null)[], tenant: string) => {
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState<TimeSeriesStatsResponse>()
  const [granularity, setGranularity] = useState<Granularity>("year")

  useEffect(() => {
    const fetchStats = async () => {
      const from = timeframe?.[0]
      const to = timeframe?.[1]
      if (!from || !to) return

      try {
        setLoading(true)

        const diffInDays = (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24)
        console.log("diffInDays: ", diffInDays)
        let res: { grouped: PeriodStats[]; pp?: PeriodStats[] } | undefined
        let gran: Granularity = "year"

        if (diffInDays > 730) {
          const forcedFrom = new Date("2021-01-01")
          const forcedTo = new Date()
          res = await totalDataService.getStatsByYear([forcedFrom, forcedTo])
          gran = "year"
        } else if (diffInDays > 31) {
          res = await totalDataService.getStatsByMonth(timeframe)
          gran = "month"
        } else if (diffInDays > 1) {
          res = await totalDataService.getStatsByDay(timeframe)
          gran = "day"
        } else {
          res = await totalDataService.getStatsByHour(timeframe)
          gran = "hour"
        }

        if (res) {
          setStats(res)
          setGranularity(gran)
        }
      } catch (err: any) {
        if (err.status === 409) return
        toast.error("Failed to load time series stats")
      } finally {
        setLoading(false)
      }
    }

    if (timeframe.filter(Boolean).length === 2) {
      fetchStats()
    }
  }, [timeframe, tenant])

  return { loading, data: stats, granularity }
}
