import React, { FC, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { TableColumnsInterface } from "../../../constants/interfaces"
import { TableHandler } from "../../../constants/type"
import { SortOrderBy, SortOrderType } from "../../../constants/eums"
import { GameSessions } from "../../../services/user/type"
import { formatTableCellForPrint } from "../../../util/formatter"
import { get } from "lodash"
import NoData from "../../NoData"
import TablePagination from "../../paginations/TablePagination"
import TableAccordionHeader from "./TableAccordionHeader"
import TableAccordionTopFilters from "./TableAccordionTopFilters"
import TableAccordionRow from "./TableAccordionRow"

type FiltersType = {
  createdAt?: (Date | null)[]
  [key: string]: any
}

export interface TableAccordionProps {
  title: string
  handler: TableHandler
  columns: TableColumnsInterface[]
  id?: string | number
  filters?: FiltersType
  buttonHandler?: any
  buttonText?: string
  defaultSortOrderBy?: SortOrderBy
  defaultSortOrder?: SortOrderType
  isExport?: boolean
  isColumnsFilterable?: boolean
  gameSessions?: GameSessions[]
  onDatePickerClick?: any
  dateData?: any
  handleDateChange?: (rowId: string, dates: string[]) => void
  content?: any
  idTrue?: boolean
}

const TableAccordion: FC<TableAccordionProps> = ({
  title,
  handler,
  columns,
  id,
  filters,
  buttonHandler,
  buttonText,
  defaultSortOrderBy = SortOrderBy.CREATED_AT,
  defaultSortOrder = SortOrderType.DESC,
  isExport = false,
  isColumnsFilterable = false,
  gameSessions,
  onDatePickerClick,
  dateData,
  handleDateChange,
  content,
  idTrue = false
}): ReactElement | null => {
  const [openLineId, setOpenLineId] = useState<false | string | number>(false)
  const toggleContent = (lineId: string | number, row: any) => {
    if (lineId === openLineId) {
      setOpenLineId(false)
    } else {
      setOpenLineId(row.id)
    }
  }
  const csvLinkEl = useRef<any>(null)

  const [rows, setRows] = useState<any>([])
  const [total, setTotal] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPages, setTotalPages] = useState<number>(1)

  const [orderBy, setOrderBy] = useState<string>(defaultSortOrderBy)
  const [order, setOrder] = useState<string>(defaultSortOrder)
  const [csvData, setCsvData] = useState<any>([])
  const [loadingCsvData, setLoadingCsvData] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeDatePicker, setActiveDatePicker] = useState<string | null>(null)
  const [selectedColumns, setSelectColumns] = useState<TableColumnsInterface[]>(
    columns.map((column) => ({
      ...column,
      selected: true
    }))
  )

  const onLoad = useCallback(
    async (
      page: number,
      pageSize: number,
      filters?: FiltersType,
      orderBy?: string,
      order?: string,
      id?: string | number
    ): Promise<void> => {
      try {
        if (idTrue && !id) return
        setLoading(true)
        const result = await handler(page, pageSize, id, filters, orderBy, order)
        if ((result?.data ?? []).length > 0) {
          setRows(result?.data)
          setPage(result?.page)
          setTotal(result?.total)
          setTotalPages(result?.total_pages)
          setLoading(false)
          return
        }
        setRows([])
        setPage(1)
        setTotal(1)
        setTotalPages(1)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    },
    [idTrue]
  )

  const onJump = useCallback(
    (jumpTo: number) => {
      if (!jumpTo) return
      onLoad(jumpTo, pageSize, filters, orderBy, order, id)
    },
    [pageSize, filters, orderBy, order, id]
  )

  const handleRequestSort = (value: string): void => {
    if (value === orderBy) {
      return setOrder(order === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC)
    }
    setOrderBy(value)
    setOrder(SortOrderType.ASC)
  }

  useEffect(() => {
    setSelectColumns(columns.map((column) => ({ ...column, selected: true })))
  }, [columns])

  useEffect(() => {
    onLoad(page, pageSize, filters, orderBy, order, id)
  }, [page, pageSize, filters, orderBy, order, id, onLoad])

  const handleExportAll = () => {
    if (!loadingCsvData) {
      setLoadingCsvData(true)
      handler(page, total, id, filters, orderBy, order)
        .then((result: any) => {
          const csvResult = result.data.map((row: any) =>
            selectedColumns
              .filter((column) => column.selected === true)
              .reduce((result, column) => {
                result[column.title] = formatTableCellForPrint(get(row, column.value), column.format)
                return result
              }, {} as any)
          )
          setCsvData(csvResult)
          setTimeout(() => csvLinkEl.current.link.click())
          setLoadingCsvData(false)
        })
        .catch(() => {
          setLoadingCsvData(false)
          setCsvData([])
        })
    }
  }

  return (
    <div className="text-white">
      <TableAccordionTopFilters
        title={title}
        rowsLength={rows.length}
        loading={loading}
        handleExportAll={handleExportAll}
        loadingCsvData={loadingCsvData}
        csvData={csvData}
        selectedColumns={selectedColumns}
        setSelectColumns={setSelectColumns}
        pageSize={pageSize}
        onPageSizeChange={(value) => setPageSize(value)}
        isExport={isExport}
        isColumnsFilterable={isColumnsFilterable}
        buttonHandler={buttonHandler}
        buttonText={buttonText}
        csvLinkEl={csvLinkEl}
      />
      {rows?.length ? (
        <div>
          <TableAccordionHeader selectedColumns={selectedColumns} handleRequestSort={handleRequestSort} orderBy={orderBy} order={order} />
          {rows.map((row: any, rowIndex: number) => {
            return (
              <TableAccordionRow
                key={rowIndex}
                toggleContent={content ? () => toggleContent(row?.id, row) : undefined}
                selectedColumns={selectedColumns}
                row={row}
                onDatePickerClick={onDatePickerClick}
                setActiveDatePicker={setActiveDatePicker}
                activeDatePicker={activeDatePicker}
                handleDateChange={handleDateChange}
                dateData={dateData}
                gameSessions={gameSessions}
                isContentOpen={openLineId === row.id}
                content={openLineId === row.id ? content(row?.id) : undefined}
                hasContent={!!content}
              />
            )
          })}
          <div className="pt-4">
            <TablePagination page={page} totalPages={totalPages} onJump={onJump} />
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default TableAccordion
